@font-face {
  font-family: "CoreSansGMedium";
  src: url("./assets/fonts/CoreSansG-Medium.ttf") format('ttf');
}

@font-face {
  font-family: "Ubuntu";
  src: url("./assets/fonts/Ubuntu-R.ttf") format('ttf');
}

@font-face {
  font-family: "HelveticaNormal";
  src: url("/fonts/helvetica-normal.ttf") format('ttf');
}

button:disabled {
  opacity: 0.5;
}

a:link {
  text-decoration: none;
  color: #000;
}

a:visited {
  text-decoration: none;
  color: #000;
}

a:hover {
  text-decoration: none;
  color: #000;
}

a:active {
  text-decoration: none;
  color: #000;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #9eb1af;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #9eb1af;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #9eb1af;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9eb1af;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9eb1af;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #9eb1af;
}

body {
  margin: 0;
  color: #61dafb;
  font-family: "HelveticaNormal", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: contain;
  font-size: "16px";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.containerSplash2 {
  background-color: #fff;

  text-align: center;
}

.bold {
  font-weight: bold;
}
.buttom-1 {
  background-color: #f4ae01;
  color: #fff;
  border-radius: 20px;

  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: 40px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.botton {
  position: absolute;
  bottom: 0;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.image-mock {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.image-beebot {
  width: 40%;
  max-width: 250px;
  height: auto;
}

.buttonQuiz {
  text-align: center;
  color: #f4ae01;
  border: 1px solid #f4ae01;
  border-radius: 20px;
  margin: 0px 40px 0px 40px;
  padding: 0px 40px 0px 40px;
}

.headerMenu {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
}

.columnCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.rowCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.rowBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.columnBetween {
  display: flex;

  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.columnStart {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.rowEnd {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: row;
}

.rowStart {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
}

.rowStartCenter {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}

.maxWidth {
  max-width: 700px;
}

.scormPresenterFullHeight {
  width: 100vw;
  height: 100vh;
  padding: 0;
  display: flex;
}

.bottonIcon{
  width:12%;

}


.title1 {
  font-weight: bold;

  font-size: 15px;
}
.subtitle1 {
  font-size: 10px;
  color: #9eb1af;
}
.quizCircle {
  background-color: #ee8508;

  color: #fff;
  font-weight: bold;
  padding: 3px;
  height: 50px;
  width: 50px;
  text-align: center;
  border-radius: 25px;
  font-size: 10px;
}
.quizCircleSmall {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.quizCircleSmallIcon {
  font-size: 10px;
}
.text1 {
  padding-left: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  color: #000;
  font-weight: normal;
  word-break: break-word;
}

.progress-title {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #f4ae01;
  transform: translate(-50%, -50%);
}

.progress-wrapper {
  margin: auto;
  position: relative;
}

.buttonCarousel {
  border: 0px;
  background-color: #ddd;
  border-radius: 12px;
  margin: 5px;
  padding-top: 3px;
  padding-bottom: 1px;
  top: 45%;
  color: #000;
  font-weight: bold;
}
button:disabled {
  opacity: 0.5;
}

.buttonCarousel:disabled {
  background-color: #ddd;
}

.dotCarousel button:disabled {
  background-color: #333;
}

.w100 {
  width: 100%;
}
.dotCarousel button {
  width: 6px;
  height: 6px;

  background-color: #ddd;
  border-radius: 12px;
  margin: 3px;
  padding: 0px;
  border: 0px;
}
.input1 {
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
  background-color: #eee;
  padding: 10px;
}

.titleWiki {
  padding-right: 10px;
  padding-top: 5px;
  margin-bottom: 10px;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
  font-size: 16px;
  color: #000;
  width: 100%;
}

.textWiki {
  width: 100%;
  word-wrap: break-word;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-top: 5px;
  font-size: 15px;
  color: #333;
  font-weight: normal;
  word-break: break-word;
}

.tagsWikiList {
  padding-left: 10px;
}

.wikiSection {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;

  padding: 5px;
  background: #eee;
  color: #000;
  word-break: break-word;
}

.wikiSection:after {
  font-family: "FontAwesome";
  content: url("/sec.png");

  position: absolute;
  right: 5px;
  top: 5px;
  display: block;
  transition: transform 300ms;
}

.wikiSection.is-open:after {
  transform: rotateZ(180deg);
}
.wikiSection.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.shadow {
  -webkit-box-shadow: 0px 2px 3px 0px rgba(214, 214, 214, 1);
  -moz-box-shadow: 0px 2px 3px 0px rgba(214, 214, 214, 1);
  box-shadow: 0px 2px 3px 0px rgba(214, 214, 214, 1);
  z-index: 2;
}
.shadow2 {
  -webkit-box-shadow: 0px -2px 3px 0px rgba(214, 214, 214, 1);
  -moz-box-shadow: 0px -2px 3px 0px rgba(214, 214, 214, 1);
  box-shadow: 0px -2px 3px 0px rgba(214, 214, 214, 1);
}
.shadow3 {
  -webkit-box-shadow: 0px 0px 5px 1px rgba(209, 209, 209, 1);
  -moz-box-shadow: 0px 0px 5px 1px rgba(209, 209, 209, 1);
  box-shadow: 0px 0px 5px 1px rgba(209, 209, 209, 1);
}

.onlyOneCarousel {
  width: 100%;
  display: flex;
}
.onlyOneCarouselVideo {
  width: 100%;
  display: flex;
}

.scrollH {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
}

.scrollH.wikiCategories {
  margin-top: 104px;
}

.section_data img {
  max-width: 100%;
}

.section_data video {
  max-width: 100%;
}

.section_data iframe {
  max-width: 100% !important;
}

.section_data p a {
  cursor: pointer;
  color: #3377b7;
}
.linkBot a {
  font-weight: bold;
  color: blue;
}

.buttonConfirm {
  background-color: #228B22;
  border-radius: 30px;
  border: none;
  min-width: 140px;
  text-align: center;
  color: #fff;
  padding: 8px;
}
.buttonCancel {
  align-items: center;
  display: flex;
  justify-content: center;
  color: #fff;
  background-color: grey;
  padding: 5px 20px;
  border-radius: 50px;
}

.buttomStatement {
  color: #fff !important;
  background-color: #f27935;
  padding: 5px 20px 5px 20px;
  border-radius: 10px;
  margin-left: 10px;
}

.titleQuizList {
  font-weight: bold;
  padding: 10px;
  font-size: 14px;
}

.titleIdQuizItem {
  font-size: 8px;
  font-weight: bold;
  margin-left: 5px;
  position: absolute;
  top: 52px;
}
.titleQuizItem {
  font-size: 11px;
  font-weight: bold;
  margin-left: 5px;
  color: rgb(7, 7, 7);
  word-wrap: break-word;
  height: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}
.descriptionQuizItem {
  font-size: 9.5px;
  font-weight: bold;
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 350px;
}
.titleSubQuizItem {
  font-size: 11px;
  margin-left: 5px;
}

.titleIconItem {
  float: right;
  margin-top: 5px;
}

.titleQuiz {
  font-weight: bold;
  color: #777;
  font-size: 16px;
}
.dateQuiz {
  font-size: 11px;
  color: #aaa;
}
.valueQuiz {
  font-size: 13px;
  font-weight: bold;
  color: #888;
}
.quizQuestion {
  font-weight: bold;
  color: #555;
  font-size: 14px;
  padding: 5px;
}

.buttomQuiz1 {
  background-color: #f4ae01;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  margin-top: 10px;
  border: 1px solid #000;
  border-radius: 20px;
  height: auto;
}

.buttomQuiz1Worng {
  background-color: #d25353;
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
  height: auto;
  box-shadow: 1px 2px 2px 0 rgba(0,0,0,.7);
  padding: 10px;
  border-radius: 10px;
  color: #fff !important;
}
.buttomQuiz1Correct{
  background-color: rgb(91, 165, 101);
  width: 100%;
  padding: 10px;
  font-size: 14px;
  margin-top: 10px;
  border-radius: 10px;
  height: auto;
  box-shadow: 1px 2px 2px 0 rgba(0,0,0,.7);
  color: #fff !important;
}

.buttomQuiz1AwsSelected{
  box-shadow: 1px 2px 2px 0 rgba(0,0,0,.7), 0px 0px 0px 2px rgba(0,0,0,1) inset;
}
.buttomQuiz2 {
  background-color: #fff;
  color: #f4ae01;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  margin-top: 10px;
  border-radius: 20px;
  height: auto;
}

.buttomQuizQuestion1 {
  padding: 10px 15px 7px 12px;
  font-size: 14px;
  margin-top: 10px;
  border-radius: 20px;
  min-height: 35px;
}
.buttomQuizQuestion2 {
  background-color: #f0f5f4;
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  padding: 10px 15px 7px 12px;
  font-size: 14px;
  margin-top: 10px;
  border-radius: 20px;
  min-height: 35px;
}

.buttomQuizQuestionCorrect {
  background-color: rgb(3, 146, 22);
  color: #fff !important;
  width: 100%;
  padding:8px;

  font-size: 14px;
  margin-top: 10px;
  border-radius: 20px;
  min-height: 35px;
}

.buttomQuizQuestionCorrectSelect {
  background-color: rgb(3, 146, 22);
  color: #fff !important;
  width: 100%;
  padding:8px;
  font-size: 14px;
  margin-top: 10px;
  border-radius: 20px;
  min-height: 35px;
  border: solid #f3b300 2px;
}

.buttomQuizQuestionWrong {
  background-color: #C00;
  color: #fff !important;
  width: 100%;
  padding:8px;
  font-size: 14px;
  margin-top: 10px;
  border-radius: 20px;
  min-height: 35px;
  border: solid transparent 2px;
}

.buttomQuizQuestionWrongSelected {
  background-color: #C00;
  color: #fff !important;
  width: 100%;
  padding:8px;
  font-size: 14px;
  margin-top: 10px;
  border-radius: 20px;
  min-height: 35px;
  border: solid #f3b300 2px;
}



.buttomQuizQuestionCorrectNotSelect {
  background-color: rgb(3, 146, 22);
  color: #fff !important;
  width: 100%;
  padding:8px;
  font-size: 14px;
  margin-top: 10px;
  border-radius: 20px;
  min-height: 35px;
  border: solid #C00 2px;
}


.quizFloatText {
  background-color: #000;
  color: #fff;
  font-size: 8px;
  font-weight: bold;
  padding: 1px 7px 1px 7px;
  border-radius: 1px;
}
.allW {
  width: 100%;
}
.buttomQuiz3 {
  color: #FFF;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;

  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  border-radius: 20px;
  height: 30px;
  border: 1px solid #5cb85c;
  background-color: #5cb85c;
}

.buttomQuizOpenSave {
  color: #fff;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;

  font-size: 14px;

  margin-top: 10px;
  border-radius: 20px;
  height: 30px;
  background-color: #ed8224;
}

.buttomQuizOpenClose {
  color: #fff;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;

  font-size: 14px;

  margin-top: 10px;
  border-radius: 20px;
  height: 30px;
  background-color: grey;
}

.successBoxTitle {
  color: #000;
  font-weight: bold;
  font-size: 16px;
}

.successBox {
  background-color: #fff;
  width: 100%;
  position: relative;
}

.floatMiddle {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ButtomOption {
  border: 1px solid #f4ae01;
  max-width: 140px;
  width: 100%;
  height: 20px;
  text-align: center;
  border-radius: 10px;
  color: #f4ae01;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 12px;
}

.ButtomOptionSelected {
  background-color: #fff;
  max-width: 140px;
  width: 100%;
  height: 20px;
  text-align: center;
  border-radius: 10px;
  color: #f4ae01;
  display: flex;
  justify-content: space-between;
  padding-left: 3px;
  padding-right: 3px;
  font-size: 12px;
  align-items: center;
}
.border {
  border-radius: 0px !important;
  border: 1px solid white !important;
}
.grid-container {
  padding: 0px !important;
}
.shadowQuiz {
  -webkit-box-shadow: 0px 0px 2px 0px rgba(122, 122, 122, 1);
  -moz-box-shadow: 0px 0px 2px 0px rgba(122, 122, 122, 1);
  box-shadow: 0px 0px 2px 0px rgba(122, 122, 122, 1);
}

.circleHeader {
  background-color: rgb(243, 243, 243);
  width: 40px;
  height: 40px;
  border-radius: 20px;
}
.titlePage {
  font-weight: bold;
  font-size: 16px;
  margin-left: 15px;
}

.titlePageStore {
  font-weight: bold;
  font-size: 16px;
  margin-top: 5px;
}

.inputTextContent{
  margin-top: 10px;
  margin-right: auto;
  margin-bottom: 8px;
  margin-left: auto;
  background-color: #FFF;
  padding-bottom: 4px;

  padding: 0px 10px 0px 10px;
}

.inputTextContent2{
  margin-top: 10px;
  margin-right: auto;
  margin-bottom: 8px;
  margin-left: auto;
  background-color: #FFF;
  padding-bottom: 4px;

  padding: 0px 10px 0px 20px;
}

.inputTextContentChat{

  background-color: #FFF;

}

.linkDrops{
  font-weight: bold;
  color: rgb(11, 50, 121);
}


.IconFeeds{
  background-color: #EEE;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-left: 5px;
}

.chatBotChangeMenu{
  background-color: #FFF;
  position: fixed;
  bottom: 55px;
  right:  20px;
  border-radius: 5px;
}

.chatBotChangeMenuButon{
  background-color: #DDD;
  cursor: pointer;
  color:blue;
  font-size: 10px;
  margin:10px;
  padding:5px;
  border-radius: 10px;
}
.link{
  color: #333;
  font-weight: bold;

}
.centerBar{
    background-color: #333 ;
    width: 2px;
    height: 100%;

}

.modal{
  /*outline: 0;
  outline-color: #FFF;*/
}

.ligthingButtom{
  border: 1px solid #555;
  border-radius: 15px;
  padding:1px;
  padding-left:4px;
  padding-right:4px;
  margin-top: 3px;
  margin-left: 3px;
}

.unfinishedNotDelivered {
  border-left: 7px solid #f7a600;
}

.unfinishedDelivered {
  border-left: 7px solid rgb(32, 51, 179);
}

.finalizedNotDelivered {
  border-left: 7px solid #f76b00;
}

.approved {
  border-left: 7px solid rgb(77, 169, 131);
}

.reproved {
  border-left: 7px solid rgb(236, 70, 48);
}

.searchItens {
  font-size: 12px;
  font-weight: 400;
  padding-top: 10px;
}

.columnSearch {
  float: left;
  padding: 5px;
}

.rowSearch{
    display: flex;
    flex-direction: column;
}

/* Clear floats after the columns */
.rowSearch:after {
  content: "";
  display: table;
  clear: both;
}

.itemSearch {
  margin:0;
}

.itemSearchCreated {
  margin: 0;
  float: left;
  font-size: 10px;
}

.searchScroll {
  width:600px;
  overflow-y:scroll;
  position:relative;
  height: 350px;
}

.imgStatement {

 max-width: 65px;
 max-height:65px;
 width: auto;
 height: auto;
}

.backButton {
  position: absolute;
  float: left;
  top: 66px;
  left: 3px;
}

.meetingBanner {
  display: block;
  margin-left: auto;
  margin-right: auto
}

@media screen and (min-width: 375px) {
  .maxWidth {
    width: 100%;
  }
  .rowStart {
    justify-content: center;
  }
}

.whiteLabelButton {
  color: #fff;
  width: 60% !important;
  padding-left: 8px;
  padding-right: 8px;
  font-size: 14px;
  margin-top: 10px;
  border-radius: 20px;
  height: 30px;
  background-color: #ed8224;
}

.divButtonWhiteLabel {
  display: contents;
}

.titleDrop {
  justify-content: left !important;
  margin-left: 10px;
}

.wikiLink {
  display: flex;
  justify-content: space-between;
  width: 100%;
  color: #FFF;
  padding: 3px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.beebotMenuNoWhiteLabel {
  width: 122px;
  max-width: 110px;
  height: 60px;
}

.beebotMenuWhiteLabelDefault {
  height: 53px;
  max-width: 110px;
  background-color: #CCC;
  border-radius: 20px 20px 0 0;
}

.beebotMenuWhiteLabel {
  height: 53px;
  width: 53px;
  max-width: 110px;
  border-radius: 100%;
  object-fit: cover;
}

.beebot-whitelabel-icon {
  height: 28px;
  max-height: 28px;
  width: 28px;
  max-width: 28px;
  border-radius: 100%;
  object-fit: cover;
}

.beebot-no-whitelabel-icon {
  height: 28px;
  max-height: 28px;
  object-fit: cover;
}

.beebotInputTextNoWhiteLabel {
  height: 18px;
  margin-left: 6px;
  height: 28px !important;
  object-fit: cover;
}

.beebotInputTextWhiteLabel {
  height: 28px;
  margin-left: 6px;
  background-color: #CCC;
  border-radius: 10px;
}

.beebotAvatarNoWhiteLabel {
  border: 1px #FFF solid;
  background-color: #FFF;
  border-radius: 25px 25px 0px 25px;
  height: 33px;
  width: 33px;
  padding: 3px;
}

.beebotAvatarWhiteLabel {
  height: 33px;
  width: 33px;
  padding: 3px;
}

.answersTextDrop {
  width: 100%;
  color: #555 !important;
}

.dropOpenHeaderTitle {
  padding-left: 10px; 
  color: #555 !important;
  font-size: 18px;
  font-weight: bold;
}

.dropContentOnlyText {
  padding-left: 10px;
  padding-top: 5px;
}

.rowInverse {
  flex-direction: row-reverse;
}

.paddingIcons {
  padding-right: 10px
}

.flex {
  flex: "0 0 auto"
}

.pb-10 {
  padding-bottom: 10px;
}

.horizontalScrollContainer, .scrollH {
  -ms-overflow-style: none;
  scrollbar-color: transparent transparent;
}

.horizontalScrollContainer::-webkit-scrollbar, .scrollH::-webkit-scrollbar {
   display: none;
}
.imgTitleWiki{
  width: 15px
}

.notFoundArticle {
  font-weight: bold;
  padding-top: 110px
}

.spaceRight {
  padding-right: 10px;
}

.beedoo-bookmark {
  scroll-margin-top: 110px;
}

p a {
  cursor: pointer;
  color: #337AB7 !important;
}