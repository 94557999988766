.menu-item {

    text-decoration: none !important;
    
    .menu-icon-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 9px;
        padding-bottom: 4px;
    }

}

.notification-circle {
    position: absolute;
    width: 19px;
    height: 18px;
    padding-top: 1px;
    top: -5px;
    bottom: 30px;
    left: 21px;
    font-size: 12px;
    color: #FFF;
    text-align: center;
    font-weight: bold;
    background-color: red;
    border-radius: 50%;
}